<template>
  <div id="container first-page">
    <el-row class="logo">
      <img :src="url_left" alt="" class="left-img" />
      <img :src="url_right" alt="" class="right-img" />
      <!-- <el-image :src="url_left" fit="fill" class="left-img"></el-image> -->
      <!-- <el-image
        :src="url_right"
        fit="fill"
        class="right-img"
        style=""
      ></el-image> -->
    </el-row>
    <!-- <el-row class="left-tips">Internal employee operation(no show)</el-row> -->

    <el-col class="internalNum" :xs="16" :sm="12" :md="8" :lg="8" :xl="7">
      <div class="internalNum-input">
        <el-input
          placeholder="input internal number"
          v-model="form.identOrder"
          @keyup.enter.native="search"
        ></el-input>
        <el-button
          icon="el-icon-search"
          size="mini"
          @click="search"
        ></el-button>
      </div>
      <el-row class="internalNum-tips">
        <!-- <el-image :src="url_left" fit="fill"></el-image> -->
        <span><span>⬆</span> input internal number</span>
      </el-row>
    </el-col>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { baseUrl } from "@/utils/api";
import { set } from "vue";
export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  data() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      url_left: require("@/assets/img/left.png"),
      url_right: require("@/assets/img/right.png"),
      domain: "",
      form: {
        identOrder: "",
      },
    };
  },
  created() {
    this.$cookies.remove("identOrder");
    this.getDomain();
    console.log(baseUrl);
  },
  mounted() {},
  destroyed() {},
  methods: {
    getDomain() {
      let obj = {
        kw: "siteurl",
      };
      this.axios({
        url: baseUrl + "/base/getList",
        method: "post",
        data: obj,
        headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          if (obj.error == 0) {
            this.domain = obj.data[0].configvalue;
            this.getRightImg();
            this.getLeftImg();
          } else {
            // this.msgError(obj.msg);
          }
          console.log(obj.data[0].configvalue);
        })
        .catch((err) => {
          console.log(1);
        });
    },
    getRightImg() {
      let obj = {
        kw: "logo_right",
      };
      this.axios({
        url: baseUrl + "/base/getList",
        method: "post",
        data: obj,
        headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          if (obj.error == 0) {
            this.url_right = this.domain + "/" + obj.data[0].configvalue;
          } else {
            // this.msgError(obj.msg);
          }
          console.log(obj);
        })
        .catch((err) => {
          console.log(1);
        });
    },
    getLeftImg() {
      let obj = {
        kw: "logo_left",
      };
      this.axios({
        url: baseUrl + "/base/getList",
        method: "post",
        data: obj,
        headers: this.headers,
      })
        .then((res) => {
          let obj = res.data;
          if (obj.error == 0) {
            this.url_left = this.domain + "/" + obj.data[0].configvalue;
          } else {
            // this.msgError(obj.msg);
          }
          console.log(obj);
        })
        .catch((err) => {
          console.log(1);
        });
    },
    search() {
      // this.axios({
      //   url: baseUrl + "/home/order/getOrderlist",
      //   method: "post",
      //   data: this.form,
      //   headers: this.headers,
      // })
      //   .then((res) => {
      //     let obj = res.data;
      //     if (obj.error == 0) {
      //     } else {
      //       this.msgError(obj.msg);
      //     }
      //     console.log(obj);
      //   })
      //   .catch((err) => {
      //     console.log(1);
      //   });
      // this.$router.push('/data')
      if (!!this.form.identOrder) {
        this.$cookies.set("identOrder", this.form.identOrder,{
          httpOnly: true,
          secure: true
        });
        setTimeout(() => {
          this.$router.push("/data");
        }, 100);
      } else {
        this.$message.error("Please Input Internal Number!");
      }

      // console.log(1);
    },
  },
};
</script>
<style >
* {
  padding: 0;
  margin: 0;
}
body,
#container {
  height: 100%;
  position: relative;
  top: 0;
  background-color: #000;
}
body {
  padding: 2rem 1.5rem !important;
}
.logo {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  /* padding: 24px 48px 0; */
  /* justify-content: space-between; */
}

.left-img {
  width: 30vw;
  height: auto;
  text-align: left;
  flex-shrink: 0;
  /* width: 500px; */
}
.right-img {
  width: 21vw;
  height: auto;
  /* flex-shrink: 0; */
  margin-left: auto;
}
.left-tips {
  color: #fff;
  text-align: left;
  font-size: 2vw;
  /* padding: 24px 48px 0; */
}

.internalNum {
  /* width: 30%; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.internalNum .internalNum-input {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 6vh;
  padding: 3px;
}

.internalNum span {
  color: #fff;
}

.internalNum .el-button {
  color: #fff;
  background-color: #0075c1;
  border: none;
  height: 100%;
  width: 15%;
}
.el-input,
.el-input__inner {
  height: 100% !important;
}
.el-button:active,
.el-button:focus,
.el-button:hover {
  color: #fff !important;
  background-color: #0075c1 !important;
  border: none;
}
.internalNum .el-button--mini,
.el-button--mini.is-round {
  /* padding: 10px; */
}

.internalNum-tips {
  margin-top: 20px;
  font-size: 2vw;
}
.left-img img {
  /* width: 60%; */
}
/* .el-image img {
  width: 10px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
} */
</style>
