import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';           // element-ui的css样式要单独引入
import '@/utils/axios'
import VueCookies from 'vue-cookies'
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.config.productionTip = false
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyBiAL4DKN6JZGbG93fzNliMCMD6Sg9llo8', // 此处填入谷歌地图申请的key
//     libraries: 'places'
//   }
// })
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
Vue.use(VueCookies)
// import Pagination from '@/components/Pagination'
Vue.use(ElementUI);   // 这种方式引入了ElementUI中所有的组件
// 全局组件挂载
// Vue.component('Pagination', Pagination)
Vue.config.productionTip = false
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function (msg) {

  this.$message({ showClose: true, message: msg, type: 'error' })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
